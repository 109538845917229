<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-title>
          <span>Regiões</span>
          <v-spacer></v-spacer>
          <v-btn
            color="info"
            outlined
            @click="modal = true, clearModal()"
          >
            <v-icon class="mr-1">
              {{ icons.mdiPlaylistPlus }}
            </v-icon>
            Novo
          </v-btn>
        </v-card-title>
        <v-row>
          <v-col
            cols="12"
          >
            <v-data-table
              :loading="loadTable"
              loading-text="Carregando dados... Por favor, aguarde"
              :headers="headers"
              :items="items"
              :items-per-page="10"
            >
              <template #[`item.edit`]="{ item }">
                <v-icon
                  color="info"
                  @click="getRegionInfo(item)"
                >
                  {{ icons.mdiPencil }}
                </v-icon>
              </template>

              <template #[`item.delete`]="{ item }">
                <v-icon
                  color="error"
                  @click="deleteRegion(item)"
                >
                  {{ icons.mdiDelete }}
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <v-dialog
          v-model="modal"
          width="600"
        >
          <v-card>
            <v-card-title
              color="error"
            >
              <h3>Região</h3>
            </v-card-title>
            <v-card>
              <v-card-text>
                <form action="">
                  <v-row>
                    <v-col
                      cols="6"
                    >
                      <v-text-field
                        v-model="region.region"
                        dense
                        outlined
                        label="Regional"
                        variant="underlined"
                      />
                    </v-col>
                    <v-col
                      cols="6"
                    >
                      <v-text-field
                        v-model="region.code_ihs"
                        dense
                        outlined
                        label="Código IHS"
                        variant="underlined"
                      />
                    </v-col>
                    <v-col
                      cols="6"
                    >
                      <v-select
                        v-model="region.group_id"
                        :items="groups"
                        dense
                        outlined
                        label="Grupo"
                        variant="underlined"
                      />
                    </v-col>
                  </v-row>
                  <v-row justify="end">
                    <v-col
                      v-if="edit"
                      cols="auto"
                    >
                      <v-btn
                        color="success"
                        prepend-icon="mdi-content-save"
                        @click="saveChanges"
                      >
                        salvar
                      </v-btn>
                    </v-col>
                    <v-col
                      v-else
                      cols="auto"
                    >
                      <v-btn
                        color="info"
                        prepend-icon="mdi-plus"
                        @click="createGroup"
                      >
                        cadastrar
                      </v-btn>
                    </v-col>
                  </v-row>
                </form>
              </v-card-text>
            </v-card>
          </v-card>
        </v-dialog>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import axiosIns from '@/plugins/axios'
import { mdiDelete, mdiPencil, mdiPlaylistPlus } from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiPlaylistPlus,
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data() {
    return {
      modal: false,
      edit: false,
      regionId: '',
      loadTable: false,
      headers: [
        { text: 'REGIONAL', value: 'region' },
        { text: 'GRUPO', value: 'group.corporate_name' },
        { text: 'COD. IHS', value: 'code_ihs' },
        { text: 'EDITAR', value: 'edit', align: 'center' },
        { text: 'DELETAR', value: 'delete', align: 'center' },
      ],
      items: [],
      infoBase: {},
      region: {},

      groups: [],
    }
  },
  async created() {
    await this.getRegions()
    await this.getGroups()
  },
  methods: {
    closeModal() {
      this.modal = false
      this.getRegions()
    },
    clearModal() {
      this.edit = false
      this.region = {
        region: '',
        code_ihs: '',
        group_id: '',
      }
    },
    async saveChanges() {
      await axiosIns
        .put(`/api/v1/records/region/update/${this.regionId}`, this.region)
        .then(async () => {
          await this.closeModal()
          await this.$swal({
            icon: 'success',
            title: 'Grupo atualizado!',
            text: 'Dados do grupo atualizados!',
            showConfirmButton: false,
            timer: 2000,
          })
        })
        .catch(async error => {
          await this.closeModal()
          await this.$swal({
            icon: 'error',
            title: 'Erro ao atualizar dados da região!',
            showConfirmButton: false,
            text: error,
          })
        })
    },
    async createGroup() {
      await axiosIns
        .post('/api/v1/records/region/store', this.region)
        .then(async () => {
          await this.closeModal()
          await this.$swal({
            icon: 'success',
            title: 'Região cadastrado!',
            text: 'Novo região cadastrado!',
            showConfirmButton: false,
            timer: 2000,
          })
        })
        .catch(async error => {
          await this.closeModal()
          await this.$swal({
            icon: 'error',
            title: 'Erro ao cadastrar região!',
            showConfirmButton: false,
            text: error,
          })
        })
    },
    async getRegionInfo(item) {
      this.modal = true
      this.regionId = item.id
      this.edit = true
      await axiosIns.get(`/api/v1/records/region/edit/${item.id}`).then(res => {
        this.infoBase = res.data.data
        this.region = res.data.data
      })
    },
    async deleteRegion(item) {
      await this.$swal({
        icon: 'warning',
        title: 'Deseja excluir o Região?',
        text: 'A operação é irreversivel, tem certeza?',
        showCancelButton: true,
        confirmButtonText: 'Sim, excluir!',
        cancelButtonText: 'Não, cancelar!',
      })
        .then(async result => {
          if (result.isConfirmed) {
            await axiosIns.delete(`/api/v1/records/region/destroy/${item.id}`).then(async () => {
              this.closeModal()
              await this.$swal({
                icon: 'success',
                title: 'Região excluido!',
                text: 'O região foi excluido!',
                showConfirmButton: false,
                timer: 2000,
              })
            })
          } else {
            await this.$swal({
              icon: 'error',
              title: 'Cancelado!',
              text: 'Operação cancelada',
              showConfirmButton: false,
              timer: 2000,
            })
          }
        })
        .catch(async error => {
          await this.$swal({
            icon: 'error',
            title: 'Erro ao excluir região!',
            text: error,
          })
        })
    },
    async getRegions() {
      this.loadTable = true
      await axiosIns.get('/api/v1/records/region/index').then(res => {
        this.items = res.data.data

        // this.items = (res.data.data)
      })
      this.loadTable = false
    },
    async getGroups() {
      this.groups = []
      await axiosIns.get('/api/v1/records/group/index').then(res => {
        // eslint-disable-next-line array-callback-return
        res.data.data.map(dt => {
          this.groups.unshift({
            text: dt.corporate_name,
            value: dt.id,
          })
        })
      })
    },
  },
}
</script>

<style>
</style>
